import logo from '../../assets/img/qulto_logo_full_color-01.png';

const Header = () => (
  <header>
    <div className="logo-container">
      <img className="logo" src={logo} alt="Qulto Logo" />
    </div>
    <div className="title-container">
      <h1>Demo alkalmazások</h1>
      <div className="warning">Kizárólag belső használatra!</div>
    </div>
  </header>
);

export default Header;
