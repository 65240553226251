import logo from '../../assets/img/qulto_logo_full_color-01.png';

const Footer = () => (
  <footer>
    <img className="logo" src={logo} alt="Qulto Logo" />
    <div className="warning">Kizárólag belső használatra!</div>
  </footer>
);

export default Footer;
