import { useState, useEffect } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import Section from './components/Section';

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const responseJSON = await fetch('data/data.json', {
      headers: { 'Content-Type': 'applicaton/json' },
    });
    const response = await responseJSON.json();

    setData(response);
  };

  return data ? (
    <>
      <Header />
      <main>
        {data.map((group, index) => (
          <Section key={index} group={group} />
        ))}
      </main>
      <Footer />
    </>
  ) : (
    <div>Betöltés...</div>
  );
};

export default App;
