import SectionHeader from '../SectionHeader';
import SectionCard from '../SectionCard';

const Section = ({ group }) => (
  <section>
    <SectionHeader title={group.title} />
    <div className="section-content">
      {group.products.map((product, index) => (
        <SectionCard key={index} product={product} />
      ))}
    </div>
  </section>
);

export default Section;
